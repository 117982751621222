$accent-color: #dc9aac;
$countdown-value-radius: 0.9em;
$carousel-dot-size: 1rem;
$light-font-color: #f2f2f2;
$dark-font-color: #747474;
$header-height: 70px;
$padding-basis: 0.8rem;
$width-lg: 1200px;
$width-md: 992px;
$width-sm: 768px;
$width-min: 320px;
$scroll-bar-width: 20px;
