.l-header {
  position: fixed;
  z-index: 1;
  padding: 0 $padding-basis;
  width: 100%;
  height: $header-height;
}

.l-header__content {
  @include responsiveWidth();
  display: flex;
  height: 100%;
}

.l-header__logo {
  width: $header-height;
  height: 100%;

  img {
    display: block;
    margin: ($header-height - 42px)/2 auto auto;
  }
}

.l-header__menu {
  display: flex;
  flex-direction: row-reverse;
  flex-grow: 1;
}

.l-header__menu-item {
  margin: 0 $padding-basis/2;
  padding: $padding-basis $padding-basis/2;

  a {
    display: inline-block;
    height: 100%;
  }
}

.header {
  background-color: rgba(0, 0, 0, 0.7);
  text-transform: uppercase;
  font-weight: 500;
}

.header__logo {
  img {
    filter: drop-shadow(0 0 3px #000);
  }
}

.header__menu-item {
  list-style-type: none;
  font-size: 0.85em;

  a {
    color: $light-font-color;
    text-decoration: none;
    line-height: calc(#{$header-height} - #{$padding-basis * 2});

    &:hover {
      color: darken($light-font-color, 33%);
    }
  }

  @include breakpoint(xs) {
    font-size: 0.75em;
  }
}
