.l-carousel-wrapper {
  margin: $carousel-dot-size $carousel-dot-size $carousel-dot-size * 2
    $carousel-dot-size;
  width: calc(50vw - 34px);
  height: calc(50vw - 34px);
  @include breakpoint(xs) {
    width: $width-min - $scroll-bar-width;
    height: $width-min - $scroll-bar-width;
  }
  @include breakpoint(lg) {
    width: $width-lg / 2 - 34px;
    height: $width-lg / 2 - 34px;
  }
}

.slick-dots li button:before,
.slick-dots li.slick-active button:before {
  color: $accent-color;
  font-size: $carousel-dot-size;
}

.slick-carousel img {
  cursor: pointer;
}

.slick-carousel > img {
  width: 566px;
  height: 566px;
}
