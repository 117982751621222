@import '__variables';
@mixin breakpoint($class) {
  @if $class == xs {
    @media (max-width: $width-sm - 1px) {
      @content;
    }
  } @else if $class == sm {
    @media (min-width: $width-sm) {
      @content;
    }
  } @else if $class == md {
    @media (min-width: $width-md) {
      @content;
    }
  } @else if $class == lg {
    @media (min-width: $width-lg) {
      @content;
    }
  } @else {
    @warn 'Breakpoint mixin supports: xs, sm, md, lg';
  }
}

@mixin responsiveWidth() {
  margin: 0 auto;
  max-width: $width-lg;
}

@mixin centerContent() {
  display: flex;
  justify-content: center;
  align-items: center;
}
