.festivities-background {
  background-image: url('/assets/festivities.jpg');
  @include breakpoint(xs) {
    background-image: url('/assets/festivities-square.jpg'),
      url('/assets/festivities.jpg');
  }
}

.festivities {
  p {
    margin: $padding-basis auto;
    max-width: 90%;
    text-align: center;
    text-indent: 0;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 0.75em;
    line-height: 1.75;
  }
}

.section--dark .festivities {
  filter: drop-shadow(0 0 3px #000);

  img {
    @include breakpoint(xs) {
      filter: drop-shadow(0 0 12px #333);
    }
  }
}

.itinerary {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 0.8em;
  font-family: 'IM Fell English', serif;
  line-height: 1.15;

  ul {
    margin-bottom: $padding-basis;

    li {
      line-height: 1.8em;
    }
  }
}

.itinerary-image {
  display: block;
  margin: 0 auto;
  min-width: 80%;
  max-width: 100%;
}

.numeral {
  margin: auto 0.1em;
  font-size: 1.8em;
  line-height: 0;
  font-variant-numeric: tabular-nums;
}
