.l-registry-items {
  @include centerContent;
  @include breakpoint(xs) {
    flex-direction: column;
  }
}

.registry {
  padding: 0 $padding-basis;
  filter: drop-shadow(0 0 3px #000);

  img {
    @include breakpoint(xs) {
      filter: drop-shadow(0 0 12px #333);
    }
  }

  .registry__title {
    display: block;
    margin-right: auto;
    margin-left: auto;
  }

  p {
    margin: $padding-basis auto;
    max-width: 40em;
    text-indent: 0;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 0.75em;
    line-height: 1.75;
  }

  .registry__items a {
    margin: $padding-basis;
    padding: $padding-basis;
    height: 100%;
  }
}
