.wedding-party-header {
  margin-bottom: 4em;
  min-width: initial;
  max-width: 500px;
  width: 80%;
  filter: drop-shadow(0 0 3px #000);
}

.l-wedding-party-row {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: center;

  @media (max-width: 480px) {
    width: 80%;
  }
}

.l-wedding-party-member {
  padding: 0.75em;
  width: calc((100vw - #{$scroll-bar-width}) / 2);

  img {
    max-width: 216px;
    width: 100%;
    border: white solid 0.4em;
    background-color: white;
  }

  h1,
  h2,
  p {
    text-indent: 0;
    font-family: 'IM Fell English', serif;
    filter: drop-shadow(0 0 12px #000) drop-shadow(0 0 3px #000);
  }

  h1 {
    margin-top: 1em;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 1.098em;
  }

  h2 {
    margin-top: 0.5em;
    margin-bottom: 1em;
    text-transform: uppercase;
    font-size: 0.8em;
    &::before {
      content: '// ';
      font-size: 1.5em;
      font-family: initial;
    }
  }

  @media (max-width: 480px) {
    width: 100%;
  }

  @include breakpoint(md) {
    width: calc((100vw - #{$scroll-bar-width}) / 3);
  }

  @media (min-width: $width-lg + $scroll-bar-width) {
    width: $width-lg / 3;
  }
}
