footer {
  height: 450px;
  background: white;
}

.footer__content {
  flex-direction: column;
  overflow-x: hidden;
  height: 100%;
  @include responsiveWidth();
  @include centerContent();
}

.footer__padding {
  flex: 1 0 auto;
}

.footer__label {
  margin-bottom: 2em;

  @media (max-width: $width-sm) {
    width: 900px;
  }
}

.footer__instafeed {
  overflow: hidden;
  width: 100%;
  height: 168px;
}

.footer__byline {
  margin-bottom: 1rem;
  font-size: 0.75em;

  a {
    color: lighten(#5e6c8d, 20%);
    &:hover {
      color: #5e6c8d;
    }
  }
}
