.l-countdown {
  display: flex;
  flex-direction: column;
  width: ($width-sm - $scroll-bar-width) / 2;
  height: ($width-sm - $scroll-bar-width) / 2 * 4 / 3;

  @include breakpoint(xs) {
    width: ($width-min - $scroll-bar-width);
    height: ($width-min - $scroll-bar-width) * 4 / 3;
  }
}

.l-countdown__img-wrapper {
  flex: 1 1 auto;
  @include centerContent();
}

.l-countdown__img {
  width: 75%;
}

.l-countdown__timer {
  display: flex;
  flex: 1 1 auto;
}

.l-timer-item {
  text-align: center;
  flex: 1 1 0;
}

.l-timer-item--hidden {
  display: none;
}

.l-timer-item__value {
  display: block;
}

.countdown {
  filter: drop-shadow(0 0 3px #000);
}

.countdown__img {
  @include breakpoint(xs) {
    filter: drop-shadow(0 0 12px #333);
  }
}

.timer-item {
  flex-direction: column;
  text-transform: uppercase;
  font-weight: 500;
  @include centerContent();
  @include breakpoint(xs) {
    font-size: 0.9em;
  }
}

.timer-item__value {
  margin: $countdown-value-radius / 2 0;
  width: $countdown-value-radius * 2;
  height: $countdown-value-radius * 2;
  border-radius: $countdown-value-radius;
  background-color: rgba(255, 255, 255, 0.25);
  font-size: 2em;
  line-height: $countdown-value-radius * 2;
}
