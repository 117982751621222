@import '__variables';
@import '__mixins';
@import '_reset';

body {
  color: $dark-font-color;
  font-family: 'Montserrat', sans-serif;
}

.l-section {
  min-height: 100vh;
}

.l-section__background {
  position: absolute;
  width: 100%;
  height: 100%;
}

.l-section__content {
  @include responsiveWidth();
  position: relative;
  padding-top: calc(#{$header-height} + #{$padding-basis});
  padding-bottom: $padding-basis;
  min-height: 100vh;
}

.l-panel {
  display: flex;
  flex-wrap: wrap;
  @include breakpoint(xs) {
    flex-direction: column;
    flex-wrap: nowrap;
  }
}

.l-panel__item {
  min-height: calc(100vh - #{$header-height} - #{$padding-basis * 2});
  flex-grow: 1;
  @include breakpoint(xs) {
    min-height: 0;
  }
}

.l-clipped-height {
  overflow-y: auto;
  margin: $carousel-dot-size 0 $carousel-dot-size * 2 0;
  padding-right: 1em;
  width: 100%;
  height: calc(50vw - 34px);
  @include breakpoint(xs) {
    overflow-y: unset;
    margin: 0;
    padding-right: 0;
    height: initial;
  }
  @include breakpoint(lg) {
    height: $width-lg / 2 - 34px;
  }
}

.l-panel__item--padded-sm {
  padding: $padding-basis;
}

.l-panel__item--padded-md {
  padding: $padding-basis * 2;
}

.l-panel__item--full-height {
  min-height: 100vh;
}

.l-panel__item--centered {
  @include centerContent();
  flex-direction: column;
}

.l-panel__item--half {
  min-width: 50%;
  max-width: 50%;
  @include breakpoint(xs) {
    max-width: initial;
  }
}

.l-panel__item--full {
  width: 100%;
}

.l-panel__item--short {
  margin-top: 3em;
  margin-bottom: 3em;
  min-height: initial;
}

.l-header-image {
  min-width: 80%;
  max-width: 90%;
}

.l-header-image--centered {
  display: block;
  margin: $padding-basis auto;
}

section {
  background: white;
}

.section--dark {
  color: $light-font-color;
}

.section--transparent {
  background: none;
}

p {
  margin: 1em 0;
  text-indent: 1em;
  font-size: 0.85em;
  font-family: 'IM Fell English', serif;
  line-height: 1.75;
}

@import '_header';
@import '_footer';
@import '_backgrounds';
@import '_carousel';
@import '_countdown';
@import '_registry';
@import '_festivities';
@import '_party';
@import '_travel';
