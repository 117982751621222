.background--centered {
  background-position: center;
  background-size: cover;
}

.background__image {
  position: absolute;
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: cover;
}

.background--fixed {
  position: fixed;
  top: 0;
  z-index: -1;
}

@mixin background($n) {
  .background__image--#{$n} {
    background-image: url('/assets/background-#{$n}.jpg');
    @include breakpoint(xs) {
      background-image: url('/assets/background-#{$n}-square.jpg'),
        url('/assets/background-#{$n}.jpg');
    }
  }
}
@for $i from 1 through 3 {
  @include background($i);
}
