$poi-size: 150px;
$poi-border: 5px;
$poi-margins: 15px;
$poi-breakpoints: $poi-size + $poi-border * 2 + $poi-margins;

#map {
  max-width: calc(100vw - #{20px + $scroll-bar-width});
  width: 100%;
  height: 50vh;
  border: solid white 10px;
}

.travel__header {
  padding-bottom: 2em;
  min-width: 300px;
  max-width: 80%;

  .section--dark & {
    filter: drop-shadow(0 0 3px #000);
  }
}

.travel__info p {
  margin: 0.4rem auto;
  max-width: 40em;
  text-align: center;
  text-indent: 0;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 0.75em;
  line-height: 1.75;

  .section--dark & {
    filter: drop-shadow(0 0 3px #000);
  }
}

.travel__image {
  max-width: 90%;
  max-height: 90%;

  &[src$='.jpg'] {
    border: solid white 10px;
  }
}

.accommodations__link {
  max-width: 90%;
  width: 660px;
  color: inherit;
  text-align: center;
  text-decoration: none;
}

.travel__bouquet {
  margin-top: 1em;
  width: 100%;
}

.travel__what-to-pack {
  p {
    max-width: 44em;
    font-size: 0.65em;
  }
}

.travel__poi p {
  margin: 0.4rem auto;
  max-width: 54em;
  text-align: center;
  text-indent: 0;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 0.75em;
  line-height: 1.75;

  &:last-of-type {
    font-size: 1em;
  }
}

.travel__poi-grid {
  display: flex;
  margin: 6em 0;
  width: $poi-breakpoints;
  flex-wrap: wrap;
  justify-content: center;

  @media (min-width: $poi-breakpoints * 2 + $scroll-bar-width) {
    width: $poi-breakpoints * 2;
  }
  @media (min-width: $poi-breakpoints * 3 + $scroll-bar-width) {
    width: $poi-breakpoints * 3;
  }
  @media (min-width: $poi-breakpoints * 4 + $scroll-bar-width) {
    width: $poi-breakpoints * 4;
  }
  @media (min-width: $poi-breakpoints * 5 + $scroll-bar-width) {
    width: $poi-breakpoints * 5;
  }

  a {
    display: block;
    overflow: hidden;
    margin: $poi-margins / 2;
    width: $poi-size + $poi-border * 2;
    height: $poi-size + $poi-border * 2;
    border: solid white $poi-border;

    img {
      width: 100%;
      height: 100%;
      transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;

      &:hover {
        transform: scale(1.1);
      }
    }
  }
}

.vignette {
  box-shadow: 0 0 33vh rgba(0, 0, 0, 0.33) inset;
}
